import React from 'react'

import Layout from '../components/layout/'
import SEO from '../components/seo'

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Page introuvable" />
    </Layout>
  )
}

export default NotFoundPage
